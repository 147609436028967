<template>
    <div class="myProject" v-loading="loading">
        <fileItem  class="projectItem" v-for="(item, index) in records" :key="index" :fileData="item"></fileItem>
        <previewDialog ref="previewDialog" client/>
    </div>
</template>

<script>
import fileItem from '@/components/fileItem/index.vue'
import previewDialog from '@/pages/project/previewDialog.vue'
    export default {
        name: 'myProject',
        data() {
            return {
                loading: false,
                pageNum: 1,
                pageSize: 10,
                records: [],
                total: undefined
            }
        },
        props: {
            type: {
                type: String,
                default: ''
            }
        },
        components: {
            fileItem,
            previewDialog
        },
        mounted() {
            this.getList()
        },
        methods: {
            getList() {
                if (this.loading || this.records.length == this.total) return
                this.loading = true
                // const requestMap = {
                //     myProject: this.$api.listMyDownloadRecord,
                //     myRecords: this.$api.getMyCollectRecords,
                //     myUpload: this.$api.listMyAttachmentByPage
                // }
                
                this.$http.Post(this.$api.listMyAttachmentByPage, {
                    pageNum: this.pageNum++,
                    pageSize: this.pageSize,
                    collectType:1
                }).then(({ data: { records = [], total } = {} }) => {
                    this.records = [...this.records ,...records.map(i => ({...i, id: i.projectId}))]
                    this.total = Number(total || 0)
                }).finally(() => {
                    this.loading = false
                })
            }
        }

    }
</script>

<style lang="less" scoped>
.myProject{
    min-height: 100%;
    .projectItem{
        width: 100%;
        &+.projectItem{
            margin-top: 32px;
        }
    }

}
</style>