<template>
    <div class="file-item">
        <div class="projectDetail-body-item">
            <!-- <p class="title">{{ item.label }}</p> -->
            <div class="file-list">
                <template v-if="[1,2,3,4,5].includes(fileData.attachmentType)">
                    <div class="file-list-item" :style="{backgroundColor: fileData.fileType == 1 ? '#FFEFE8' : fileData.fileType == 2 ? '#E8F6FF' : '#FFF2F2'}">
                        <img class="cover" :src="coverMap[fileData.fileType]" alt="">
                        <div class="right">
                            <div class="file-title">
                                <span>{{fileData.originName || fileData.fileName}}</span>
                                <div class="public" v-if="fileData.isPublic">公开</div>
                            </div>
                            <div class="file-size">
                                {{formatSize(fileData.fileSize || 0)}}
                                <div class="operate">
                                    <span  @click="preview(fileData)">预览</span>
                                    <i>|</i>
                                    <span  @click="downLoad(fileData)">下载</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else-if="fileData.attachmentType == 6">
                    <div class="file-list-enterpriseConcat">
                        <!-- <div style="margin-right: 20px;">
                            <el-badge value="公开" :hidden="!fileData.isPublic">
                                <img class="user" src="@/assets/project/user.png" style="width: 72px;height:72px;" mode="" />
                            </el-badge>
                        </div> -->
                        <div class="line">
                            <img class="icon" src="@/assets/project/user1.png" mode="" />
                            <span class="name">姓名</span>
                            {{fileData.enterpriseConcatName}}
                        </div>
                        <div class="line">
                            <img class="icon" src="@/assets/project/tel.png" mode="" />
                            <span class="name">手机号</span>
                            {{fileData.enterpriseConcatPhone}}
                        </div>
                        <div class="line">
                            <img class="icon" src="@/assets/project/position.png" mode="" />
                            <span class="name">职务</span>
                            {{fileData.enterpriseConcatPosition || '---'}}
                        </div>
                        <div class="line line-card">
                            <img class="icon" src="@/assets/project/tel.png" mode="" />
                            <span class="name">名片</span>
                            <el-button class="showConcate" type="primary" size="mini" @click="lookEnterpriseConcat(fileData)" :loading="publicUserLoading">查看</el-button>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <el-empty :image-size="70" description="暂无数据"></el-empty>
                </template>
            </div>
        </div>
        <!-- 公开联系人查看 -->
        <!-- <el-dialog
            title="查看联系人"
            :visible.sync="publicUserVisible"
            width="400px">
            <p class="publicUser-line">姓名：{{ fileData.enterpriseConcatName }}</p>
            <p class="publicUser-line">手机号：{{ fileData.enterpriseConcatPhone }}</p>
            <p class="publicUser-line">职务：{{ fileData.enterpriseConcatPosition }}</p>
            <p class="publicUser-line">
                名片：
                <img v-if="fileData.enterpriseConcatBusinessCard" :src="fileData.enterpriseConcatBusinessCard" style="width: 100%;" alt="">
            </p>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="publicUserVisible = false">确 定</el-button>
            </span>
        </el-dialog> -->
    </div>
</template>

<script>
const coverMap = {
	1: require('@/assets/fileIcon/pdf.png'),
	2: require('@/assets/fileIcon/word.png'),
	3: require('@/assets/fileIcon/xsl.png')
}
export default {
    name: 'fileItem',
    props: {
        fileData: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        const formatSize = (data) => {
            const val = Number(data || 0)
            if (!val) return '0 B';
            const k = 1024;
            const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
            const i = Math.floor(Math.log(val) / Math.log(k));
            return parseFloat((val / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
        }
        return {
            formatSize,
            coverMap,
            publicUserVisible: false
        }
    },
    methods: {
        lookEnterpriseConcat() {
            if (!this.fileData.enterpriseConcatBusinessCard) return this.$message.warning('暂无名片')
            const h = this.$createElement;
            return this.$msgbox({
                title: '名片',
                message: h('img', {
                attrs: {
                    src: this.fileData.enterpriseConcatBusinessCard
                },
                style: 'width: 390px;height: auto;' }),
                showCancelButton: false
            })
        },
        async preview(item) {
            // const { code, msg } = await this.$http.Post(this.$api.downloadAttachment, {
            //     attachmentId:item.id,
            // });

            // if (code != 200) return this.$message.error(msg)
            this.$parent.$refs.previewDialog.open(item. id)
        },
        async downLoad(item = this.fileData) {
        // const params = {
        //   id:item.id,
        //   attachmentType:item.attachmentType,
        // }
            // let {data} = await this.$http.Post(this.$api.downloadAttachment,  params );
            // this.$confirm(`下载需要消耗1个${this.coin[item.attachmentType - 1]}兑换${this.attachmentMaps[item.attachmentType]}?`, '提示', {
            //     confirmButtonText: '确定',
            //     cancelButtonText: '取消',
            //     type: 'warning'
            // }).then(async () => {
                
            const { code, data: fileKey, msg } = await this.$http.Post(this.$api.downloadAttachment, {
                attachmentId:item.id,
            });

            if (code != 200) return this.$message.error(msg)
            
            const notify = this.$notify({
                title: '下载中',
                message: '请稍等...',
                duration: 0
            });
            const { data: { data, filename } } = await this.$http.DownLoadFile(this.$api.downloadFileByKey, {
                fileKey
            })
            // download(data, filename)
            const methods = new this.Global()
            // console.log(data)
            methods.downloadBlob(data, decodeURIComponent(filename))
            this.$message({
                type: 'success',
                message: '操作成功'
            }); 
            notify.close()
            // }).catch((e) => {
            //     console.log(e)
            //     this.$message({
            //     type: 'info',
            //     message: '已取消'
            //     });          
            // });
        }
    },
}
</script>

<style lang="less" scoped>
.projectDetail{
    &-body{
        .el-empty{
            padding: 0;
        }
        &-item{
            padding: 0 10px;
            background-color: #fff;
            margin-bottom: 16px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            .title{
                width: 90px;
                margin-right: 20px;
                color: #2e3742;
                font-size: 16px;
            }
            .file-list{
                flex: 1;
                width: 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 0 10px;
                &-item{
                    flex: 1;
                    padding: 24px;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    box-sizing: border-box;
                    .cover{
                        width: 40px;
                        height: 40px;
                        margin-right: 15px;
                    }
                    .right{
                        width: 0;
                        flex: 1;

                    }
                    .file-title{
                        color: #2e3742;
                        font-size: 16px;
                        margin-bottom: 6px;
                        display: flex;
                        align-items: center;
                        span{
                            white-space: nowrap;      /* 确保文本在一行内显示 */
                            overflow: hidden;         /* 隐藏溢出的内容 */
                            text-overflow: ellipsis;
                            flex: 1;
                            width: 0;
                        }
                        .public{
                            background-color: rgba(28, 104, 255, 0.05);
                            height: 20px;
                            padding: 0 6px;
                            border: 1px solid rgba(28, 104, 255);
                            border-radius: 5px;
                            font-size: 12px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #1C68FF;
                            font-weight: 500;
                        }
                    }
                    .file-size{
                        color: #95a1af;
                        font-size: 13px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .operate{
                            span{
                                cursor: pointer;
                            }
                            span, i {
                                color: #2e3742;
                                font-size: 14px;
                            }
                            i{
                                margin: 0 22px;
                            }
                        }
                    }
                }
                &-enterpriseConcat{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex: 1;
                    .line{
                        display: flex;
                        align-items: center;
                        flex: 1;
                        color: #2e3742;
                        font-size: 14px;
                        img{
                            width: 18px;
                            height: 18px;
                        }
                        .name{
                            color: #95a1af;
                            font-size: 14px;
                            margin: 0 7px;
                        }
                        &-card{
                            display: flex;
                            justify-content: end;
                            .showConcate{
                                padding: 8px 32px;
                                background-color: #1C68FF;
                                border-radius: 6px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>