<template>
    <div class="personalCenterClient">
        <div class="personalCenterClient-title">个人中心</div>
        <div class="personalCenterClient-container">
            <div class="personalCenterClient-container-left">
                <div class="header">
                    <el-avatar shape="circle" :size="93" fit="contain" :src="user.avatar"></el-avatar>
                    <div class="header-detail">
                        <p class="nickname">{{ user.nickname }}</p>
                        <p class="focusAreasName">
                            <span v-for="item in (user.focusAreasName || '').split(',').filter(i => i)" :key="item">{{ item }}</span>
                        </p>
                        <p class="enterpriseName">{{ user.enterpriseName }}</p>
                    </div>
                </div>
                <div class="content">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane :label="item.title" :name="item.type" v-for="(item, index) in tabs" :key="index">
                        </el-tab-pane>
                    </el-tabs>
                    <div class="content-component">
                        <el-scrollbar ref="scroll" class="scroll" wrap-class="scrollbar-wrapper" view-class="scrollbar-view">
                            <div :key="activeCom.key">
                                <components ref="com" :is="activeCom.com" :type="activeCom.type" :key="activeCom.type"></components>
                            </div>
                        </el-scrollbar>
                    </div>
                </div>
            </div>
            <div class="personalCenterClient-container-right">
                <div class="my-message">
                    <myMessage></myMessage>
                </div>
                <div class="customer-service">
                    <p class="title">
                        联系客服
                    </p>
                    <img class="qrCode" src="@/assets/qrcode.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import myWallet from '@/pages/personalCenterClient/myWallet.vue'
import myProject from '@/pages/personalCenterClient/myProject.vue'
import myCollectRecords from '@/pages/personalCenterClient/myCollectRecords.vue'
import myMessage from '@/pages/personalCenterClient/myMessage.vue'
import myUploadFile from '@/pages/personalCenterClient/myUploadFile.vue'
export default {
    name: 'personalCenterClient',
    components: {
        myWallet,
        myProject,
        myCollectRecords,
        myMessage,
        myUploadFile
    },
    data() {
        return {
            activeName: 'myWallet',
            tabs: [
                {
                    title: '我的货币',
                    type: 'myWallet'
                },
                {
                    title: '我的下载',
                    type: 'myProject',
                    com: 'myProject'
                },
                {
                    title: '我的收藏',
                    type: 'myRecords',
                    com: 'myProject'
                },
                // {
                //     title: '我的上传',
                //     type: 'myUpload',
                //     com: 'myUploadFile'
                // },
                // {
                //     title: '我的消息',
                //     type: 'myMessage'
                // }
            ]
        }
    },
    computed: {
        ...mapState(['user']),
        activeCom() {
            const { tabs, activeName } = this
            const active = tabs.find(i => i.type == activeName)
            return {
                type: active.type,
                com: active.com || active.type
            }
        }
    },
    async mounted() {
        await this.$nextTick()
        this.$refs.scroll.wrap.addEventListener("scroll", this.scrollContent,true)
    },
    methods: {
        handleClick(e) {
            console.log(e)
        },
        async scrollContent(e) {
            await this.$nextTick()
            let poor=e.target.scrollHeight - e.target.clientHeight - 2
            if( poor <= parseInt(e.target.scrollTop) || poor <= Math.ceil(e.target.scrollTop) || poor <= Math.floor(e.target.scrollTop) ){
                this.$refs?.com?.getList && this.$refs?.com?.getList()
            }
        }
    }
}
</script>

<style lang="less" scoped>
.personalCenterClient{
    &-title{
        width: 1125px;
        margin: 0 auto;
        color: #2e3742;
        font-size: 14px;
        padding: 19px 0 15px;
    }
    &-container{
        width: 1125px;
        display: flex;
        margin: 0 auto;
        &-left{
            width: 789px;
            background-color: #fff;
            padding: 38px 0 0 0;
            display: flex;
            flex-direction: column;
            height: calc(100vh - 140px);
            border-radius: 8px;
            box-sizing: border-box;
            .header{
                padding: 0 36px;
                display: flex;
                align-items: center;
                margin-bottom: 43px;
                &-detail{
                    margin-left: 16px;
                    flex: 1;
                    .nickname{
                        color: #2e3742;
                        font-size: 24px;
                    }
                    .focusAreasName{
                        display: flex;
                        align-items: center;
                        margin: 12px 0 6px;
                        span{
                            border-radius: 5.5px;
                            opacity: 1;
                            border: 1.37px solid rgba(28, 104, 255, 0.4);
                            background: rgba(28, 104, 255, 0.05);
                            color: rgb(28, 104, 255);
                            font-size: 12px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding:  0 6px;
                            height: 20px;
                            box-sizing: border-box;
                            &+span{
                                margin-left: 14px;
                            }
                        }
                    }
                    .enterpriseName{
                        color: rgb(149, 161, 175);
                        font-size: 16px;
                    }
                }
            }
            .content{
                flex: 1;
                height: 0;
                display: flex;
                flex-direction: column;
                /deep/.el-tabs{
                    .el-tabs__header{
                        margin: 0;
                        .el-tabs__nav-wrap{
                            padding: 0 36px;
                            &::after{
                                height: 1px;
                            }
                            .el-tabs__item{
                                color: rgb(103, 117, 131);
                                font-size: 16px;
                                &.is-active{
                                    color: rgb(46, 55, 66);
                                    font-size: 16px;
                                    font-weight: 600;
                                }
                            }
                        }
                    }
                    .el-tabs__content{
                        flex: 1;
                        height: 0;
                        .el-tab-pane{
                            height: 100%;
                        }
                    }
                } 
                &-component{
                    flex: 1;
                    height: 0;
                    padding: 36px;
                    box-sizing: border-box;
                    .scroll{
                        height: 100%;
                        width: 100%;
                        /deep/.scrollbar-wrapper{
                            overflow-x: hidden;
                            .scrollbar-view{
                                min-height: 100%;
                            }
                        }
                    }
                }
            }
        }
        &-right{
            width: 0;
            flex: 1;
            height: calc(100vh - 140px);
            display: flex;
            flex-direction: column;
            margin-left: 16px;
            // flex: 1;
            // height: 100%;
            .customer-service{
                padding: 24px;
                background-color: #fff;
                border-radius: 8px;
                .title{
                    color: rgb(46, 55, 66);
                    font-size: 16px;
                    font-weight: 500;
                }
                .qrCode{
                    width: 170px;
                    display: block;
                    margin: 27px auto;
                }
            }
            .my-message{
                flex: 1;
                height: 0;
                margin-bottom: 16px;
                background-color: #fff;
                border-radius: 8px;
                padding: 24px;
                // display: flex;
            }
        }
    }
}
</style>