<template>
    <div class="myWallet" v-loading="loading">
		<div class="list" v-for="(item, index) in list" :style="{backgroundColor: item.color}" :key="index">
			<div class="title"><span class="name">{{item.title}}：</span>{{item.count}}</div>
			<div class="desc"><span class="name">说明：</span>{{item.desc}}</div>
			<div class="proportion"><span class="name">下载机制：</span>{{item.proportion}}</div>
		</div>
        <p class="tip">注:不同货币之间不能兑换</p>
    </div>
</template>

<script>
export default{
    data() {
        return {
            list: [
                {
                    title: '红钻数量',
                    count: 0,
                    desc: '红钻用于兑换企业联系方式，上传企业联系方式赚取红钻',
                    proportion: '1:1',
                    color: '#e43d33',
                    key: 'zincCoin'
                },
                {
                    title: '黄钻数量',
                    count: 0,
                    desc: '黄钻用于兑换企业投决报告，上传企业投决报告赚取黄钻',
                    proportion: '1:1',
                    color: '#f3a73f',
                    key: 'platinumCoin'
                },
                {
                    title: '金币数量',
                    count: 0,
                    desc: '金币用于兑换企业尽调报告，上传企业尽调报告赚取金币',
                    proportion: '1:1',
                    color: '#ffd800',
                    key: 'goldCoin'
                },
                {
                    title: '银币数量',
                    count: 0,
                    desc: '银币用于兑换企业立项报告，上传企业立项报告赚取银币',
                    proportion: '1:1',
                    color: '#dbdbdb',
                    key: 'silverCoin'
                },
                {
                    title: '铜币数量',
                    count: 0,
                    desc: '铜币用于兑换企业访谈纪要，上传企业访谈纪要赚取铜币',
                    proportion: '1:1',
                    color: '#dcd2b3',
                    key: 'copperCoin'
                },
                {
                    title: '铁币数量',
                    count: 0,
                    desc: '铁币用于兑换企业BP，上传企业BP赚取铁币',
                    proportion: '1:1',
                    color: '#95a584',
                    key: 'ironCoin'
                }
            ],
            loading: true
        }
    },
    mounted() {
        this.gteMywallet()
    },
    methods: {
        gteMywallet() {
            this.loading = true
            this.$http.Post(this.$api.myWallet, {}).then(({ data }) => {
                this.list.forEach(item => {
                    item.count = data[item.key] || 0
                })
            }).finally(() => {
                this.loading = false
            })
        }
    }
}
</script>

<style lang="less" scoped>
.myWallet{
    .list{
        color: #fff;
        padding: 15px;
        border-radius: 8px;
        .name{
            width: 120px;
            display: block;
        }
        .title{
            font-size: 18px;
            margin-bottom: 10px;
            display: flex;
        }
        .desc{
            font-size: 14px;
            margin-bottom: 5px;
            display: flex;
        }
        .proportion{
            font-size: 14px;
            display: flex;
        }
        &+.list{
            margin-top: 10px;
        }
    }
    .tip{
        color: #000;
        font-size: 14px;
        margin: 10px 0;
    }
}
</style>