<template>
    <div class="myMessage" v-loading="loading">
        <div class="myMessage-title">消息</div>
        <div class="myMessage-content">
            <el-scrollbar class="scroll" ref="scroll" style="height: 100%;">
                <div>
                    <div class="myMessage-content-item" v-for="(item, index) in records" :key="index">
                        <p class="title">{{ messageTypeMap[item.msgType] }}</p>
                        <p class="note">{{ item.msg }}</p>
                    </div>
                </div>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'myMessage',
        data() {
            return {
                loading: false,
                records: [],
                pageNum: 1,
                pageSize: 10,
                total: undefined,
				messageTypeMap: {
					1: '附件上传通知',
					4: '附件审核通知'
				}
            }
        },
        async mounted() {
            await this.$nextTick()
            this.$refs.scroll.wrap.addEventListener("scroll", this.scrollContent,true)
            this.getList()
        },
        methods: {
            getList() {
                console.log(123)
                if (this.loading || this.records.length == this.total) return
                this.loading = true
                this.$http.Post(this.$api.messagtList, {
                    pageNum: this.pageNum++,
                    pageSize: this.pageSize
                }).then(({ data: { records = [], total } = {} }) => {
                    this.records = [...this.records ,...records.map(i => ({...i, id: i.projectId}))]
                    this.total = Number(total || 0)
                }).finally(() => {
                    this.loading = false
                })
            },
            async scrollContent(e) {
                await this.$nextTick()
                let poor=e.target.scrollHeight - e.target.clientHeight - 2
                if( poor <= parseInt(e.target.scrollTop) || poor <= Math.ceil(e.target.scrollTop) || poor <= Math.floor(e.target.scrollTop) ){
                    this.getList()
                }
            }
        }

    }
</script>

<style lang="less" scoped>
.myMessage{
    height: 100%;
    display: flex;
    // display: flex;
    flex-direction: column;
    &-title{
        color: #2e3742;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 12px;
    }
    &-content{
        flex: 1;
        height: 0;
        
        /deep/.scroll{
            .el-scrollbar__wrap{
                overflow-x: hidden;
            }
        }
        &-item{
            padding-bottom: 16px;
            .title{
                color: #2e3742;
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 6px;
                word-break: break-all;
            }
            .note{
                color: rgb(46, 55, 66);
                font-size: 14px;
                word-break: break-all;
                line-height: 1.5;
            }
            &+.myMessage-content-item{
                padding-top: 16px;
                line-height: 1.5;
                border-top: 1px solid #E4E7ED;
            }
        }
    }
}
</style>